/* eslint-disable */ 

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseApp = initializeApp({
  apiKey: "AIzaSyA0PYFCSRUfRh7tac6-RhH2kUliawdLKng",
  authDomain: "orianhelpdesk.firebaseapp.com",
  projectId: "orianhelpdesk",
  storageBucket: "orianhelpdesk.appspot.com",
  messagingSenderId: "539142674523",
  appId: "1:539142674523:web:2f8bb1a1ee748839fc077e",
  measurementId: "G-DV4QDTZG3Q"
})

export const db = getFirestore()
export const auth = getAuth(firebaseApp)

export default firebaseApp