/* eslint-disable */ 

import React, { useState } from 'react';

import Form, {
    SimpleItem,
    GroupItem,
    Label
  } from 'devextreme-react/form';

import { Toast } from 'devextreme-react/toast'

import {
    CCard,
    CCardBody,
    CCardTitle,
    CCardSubtitle,
    CCardText,
    CListGroup,
    CListGroupItem,
    CButton
    } from '@coreui/react'

import 'devextreme-react/html-editor';
import 'devextreme-react/date-box';

import moment from 'moment'

import { db } from '../../../firebase'
import { doc, setDoc } from 'firebase/firestore'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';

export const Ticket = (props) => {
    const [toast, setToast] = useState(false)
    const [disabled, setDisabled] = useState(false)

    // recives ticket number as prop to then use to populate view modal
    const [Ticket, loading, error] = useDocumentDataOnce(
        doc(db, 'tickets', `${props.match.params.ticketNumber}`)
    )

    let detailsOptions = {
        width: '100%', 
        height: 'auto',
        disabled: true
    }

    let resolveDetailsOptions = {
        width: '100%',
        height: 'auto'
    }

    // closes ticket off
    const CloseTicket = () => {
        Ticket.ticket_status = 10
        Ticket.ticket_status_text = 'Closed'
        Ticket.ticket_resolved_at = moment(Date.now()).valueOf()
        Ticket.x = 2 // sets x so that wont show up in current ticket list
        setDoc(doc(db, 'tickets', `${Ticket?.ticket_number}`), Ticket, {merge: true})
        setToast(true)
    }

    return(
        <>
            <CCard>
                <CCardBody>
                    <CCardTitle>Ticket {Ticket?.ticket_number}</CCardTitle>
                    <CCardSubtitle>{Ticket?.ticket_subject} - {Ticket?.ticket_site}</CCardSubtitle>
                    <CListGroup flush>
                        <CListGroupItem>Contact Name: {Ticket?.ticket_contact_name}</CListGroupItem>
                        <CListGroupItem>Contact Email: <a href={"mailto:" + Ticket?.ticket_contact_email}>{Ticket?.ticket_contact_email}</a></CListGroupItem>
                        <CListGroupItem>Contact Phone: <a href={"tel:" + Ticket?.ticket_contact_phone}>{Ticket?.ticket_contact_phone}</a></CListGroupItem>
                    </CListGroup>
                        <Form formData={Ticket} labelMode="floating">
                            <GroupItem>
                                <SimpleItem dataField="ticket_details" editorType="dxHtmlEditor" editorOptions={detailsOptions}>
                                    <Label text="Job Details" location="top" alignment="center" />
                                </SimpleItem>
                                <SimpleItem dataField="ticket_resolved_details" editorType="dxHtmlEditor" editorOptions={resolveDetailsOptions}>
                                    <Label text="Please type in the resolve details below" location="top" alignment="center" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem>
                                <CButton color="primary" onClick={() => {CloseTicket()}}>
                                    Resolve Ticket!
                                </CButton>
                            </GroupItem>
                        </Form>
                </CCardBody>
            </CCard>
            <Toast
                    visible={toast}
                    message="Ticket resolved and customer notifted"
                    type="success"
                    onHiding={() => setToast(false)}
                    closeOnOutsideClick={true}
                    closeOnClick={true}
                    closeOnSwipe={true}
                />
        </>
    )
}